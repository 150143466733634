import React, { useState, useEffect, useRef } from 'react';

import { poolLogsModel } from './models/poolLogs.model';
import { excelFileModel } from './models/excelFileModel';
import { useTranslation } from 'react-i18next';//Dil
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ErrorBoundary from '../../plugins/ErrorBoundaries/ErrorBoundary';
import { JwtHelper } from '../../plugins/jwtHelper';
import { SplitButton } from 'primereact/splitbutton';
import { Panel } from 'primereact/panel';
import { MultiSelect } from 'primereact/multiselect';
import { DatatableCostum } from '../../assets/layout/DatatableCustom.scss';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

import { sendLogsToDebt, sendDoubleLogsToDebt, addPoolLog, uploadExcelPoolLog } from '../poolLogs/services/poolLogs.service';
import { getPoolList, updatePool, deletePool, getPoolById, filterPools, sendFindDataLogs, getAllCreditCards, updateSubAccountManual, updateSubaccountAllOfPools } from '../pools/services/pools.service';

import { getSubAccountById, getSubAccountList } from '../subAccount/services/subAccount.service';
import { getInsuranceProductList } from '../insuranceProduct/services/insuranceProduct.service';
import { getCurrencyTypesList } from '../currencyTypes/services/currencyTypes.service';
import { getCurrenciesByDate } from '../currencies/services/currencies.service';
import { getProductProportionsListBySubAccountId } from '../productProportions/services/productProportions.service';
import { filterModel } from './models/filterModel';

import { Paginator } from 'primereact/paginator';
import DbCurrencies from '../../plugins/DbCurrency/getDbCurrency';
import { Exception } from 'sass';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import ProgressBarDiv from '../../plugins/progressBar';

const undf = undefined;

const PoolLogs = () => {

    //--------Lang
    const { t } = useTranslation();

    //--------States
    const [poolLogs, setPoolLogs] = useState([]);
    const [poolLog, setPoolLog] = useState(poolLogsModel);
    // const [filters, setFilters] = useState([]);
    const [filter, setFilter] = useState(filterModel);
    const [excelFile, setExcelFile] = useState(excelFileModel);

    const [submitted, setSubmitted] = useState(false);
    const [poolLogSaveDialog, setPoolLogSaveDialog] = useState(false);
    const [poolManualSubAccountSaveDialog, setPoolManualSubAccountSaveDialog] = useState(false);
    const [poolLogDeleteDialog, setPoolLogDeleteDialog] = useState(false);
    const [sendLogsDialog, setSendLogsDialog] = useState(false);
    const [sendDoubleLogsDialog, setSendDoubleLogsDialog] = useState(false);
    const [findDataLogsDialog, setFindDataLogsDialog] = useState(false);
    const [sendOASendDataDialog, setSendOASendDataDialog] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [rowClick, setRowClick] = useState(true);
    //-------Submit
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [isValidatedName, setisValidatedName] = useState(true);
    const [isValidatedProductCode, setisValidatedProductCode] = useState(true);
    const [isValidatedInsuranceCompanyId, setisValidatedInsuranceCompanyId] = useState(true);
    const [isValidatedSelectSubAccountsValue, setisValidatedselectSubAccountsValue] = useState(true);
    const [isValidatedSelectCurrencyTypesValue, setisValidatedSelectCurrencyTypesValue] = useState(true);
    const [isValidatedSelectInsuranceProductsValue, setisValidatedSelectInsuranceProductsValue] = useState(true);
    const [isValidatedTaxOrTcNum, setValidatedTaxOrTcNum] = useState(true);
    const [isValidatedPolicyNumber, setisValidatedPolicyNumber] = useState(true);
    const [isValidatedAddendumNo, setisValidatedAddendumNo] = useState(true);
    const [isValidatedIssueDate, setisValidatedIssueDate] = useState(true);
    const [isValidatedPolicyStartDate, setisValidatedPolicyStartDate] = useState(true);
    const [isValidatedPolicyEndDate, setisValidatedPolicyEndDate] = useState(true);
    const [isValidatedGrossPremium, setisValidatedGrossPremium] = useState(true);
    const [isValidatedTax, setisValidatedTax] = useState(true);
    const [isValidatedCurrencyRate, setisValidatedCurrencyRate] = useState(true);
    const [isValidatedIncomingComm, setisValidatedIncomingComm] = useState(true);
    const [isValidatedSubAccountProductCommPer, setisValidatedSubAccountProductCommPer] = useState(true);



    const [insuranceProducts, setInsuranceProducts] = useState([]);
    const [currencyTypes, setCurrencyTypes] = useState([]);
    const [subAccounts, setSubAccounts] = useState([]);
    const [subAccountsforFilter, setSubAccountsforFilter] = useState([]);
    const [creditCardFilter, setCreditCardFilter] = useState([]);
    const [productProportions, setProductProportions] = useState([]);
    const [selectedLogs, setSelectedLogs] = useState(null);
    //--------Multiselect
    const [selectSubAccountsValue, setSelectSubAccountsValue] = useState(null);
    const [selectManualSubAccountsValue, setSelectManualSubAccountsValue] = useState(null);
    const [selectCreditCardValue, setSelectCreditCardValue] = useState([]);
    const [selectInsuranceProductsValue, setSelectInsuranceProductsValue] = useState(null);
    const [selectCurrencyTypesValue, setSelectCurrencyTypesValue] = useState(null);

    const [issueDate, setIssueDate] = useState(null);
    const [policyStartDate, setPolicyStartDate] = useState(null);
    const [policyEndDate, setPolicyEndDate] = useState(null);
    const [filterStartDate, setFilterStartDate] = useState(null);
    const [filterEndDate, setFilterEndDate] = useState(null);
    const [filterCreditCardChecked, setFilterCreditCardChecked] = useState(false);


    // const [date, setDate] = useState(new Date());


    const [customerTypeTcShow, setCustomerTypeTcShow] = useState('hidden field col-2');
    const [customerTypeTaxShow, setCustomerTypeTaxShow] = useState('hidden field col-2');
    const [isInputDisabled, setIsInputDisabled] = useState(true);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);





    // const [selectYesNoValue, setSelectYesNoValue] = useState(null);
    // const selectYesNoValues = [
    //     { name: 'Evet', code: true },
    //     { name: 'Hayır', code: false }
    // ];

    //----------form data Start



    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(50);
    const [totalRecords, setTotalRecords] = useState(50);

    //--------Ref.s
    const toast = useRef(null);
    const dt = useRef(null);

    const [messageOA, setMessageOA] = useState([]);

    addLocale('tr', {
        firstDayOfWeek: 1,
        dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cuma'],
        dayNamesShort: ['Pzr', 'Pzrts', 'Sal', 'Çar', 'Per', 'Cuma', 'Cumrts'],
        dayNamesMin: ['P', 'Pt', 'S', 'Ç', 'P', 'C', 'Ct'],
        monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        monthNamesShort: ['Ock', 'Şub', 'Mart', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Ek', 'Kas', 'Arl'],
        today: 'Bugün',
        clear: 'Temizle'
    });





    //Get---------
    useEffect(() => {
        //debugger;
        getSubAccountList()
            .then(data => {
                setSubAccounts(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));

                // data[0] = { name: "Seçiniz", id: 0 };

                setSubAccountsforFilter(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));


            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('SubAccountsListGettingError') + ': ' + err.data}`, life: 5000 });
            })


        getAllCreditCards()
            .then(data => {

                setCreditCardFilter(data.map(function (item, i) {
                    return { name: item.label, code: item.id };
                }));

            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('CreditCardsGettingError') + ': ' + err.data}`, life: 5000 });
            })

        getInsuranceProductList()
            .then(data => {
                setInsuranceProducts(data.map(function (item, i) {
                    // console.log(item.name + " - " + item.id)
                    return { name: item.productCode + " - " + item.insuranceCompany.name + " - " + item.name, code: parseInt(item.id), productCode: item.productCode };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('insuranceProductsListGettingError') + ': ' + err.data}`, life: 5000 });
            })


        getCurrencyTypesList()
            .then(data => {
                setCurrencyTypes(data.map(function (item, i) {
                    return { name: item.name, code: parseInt(item.id) };
                }));
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('CurrencyTypesListGettingError') + ': ' + err.data}`, life: 5000 });
            })



        setSubmitted(a => !a);
    }, [t]);

    //-------Validation(Check)
    const validForm = () => {
        //
        let tempNoti = [];
        try {

            //selectSubAccountsValue
            if (selectSubAccountsValue === null || selectSubAccountsValue.code === "" || selectSubAccountsValue.code === 0 || selectSubAccountsValue.code === undf) {

                tempNoti.push({ severity: 'error', summary: t('Kart Değeri'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedselectSubAccountsValue(false);

            } else {

                poolLog.subAccountId = selectSubAccountsValue.code;
                poolLog.subAccount = {
                    id: selectSubAccountsValue.code,
                    name: selectSubAccountsValue.name
                };
                setisValidatedselectSubAccountsValue(true);
            }

            //subAccountId
            if (poolLog.subAccountId === "" || poolLog.subAccountId === 0 || poolLog.subAccountId === undf) {

                tempNoti.push({ severity: 'error', summary: t('Kart Seçimi'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedInsuranceCompanyId(false);

            } else {
                setisValidatedInsuranceCompanyId(true);
            }

            debugger;
            //tax or tc  ValidatedTaxOrTcNum
            if ((poolLog.subAccountTaxNumber === undf || poolLog.subAccountTcNumber === undf) || (poolLog.subAccountTcNumber === "" && poolLog.subAccountTaxNumber === "")) {

                tempNoti.push({ severity: 'error', summary: t('TC Kimlik Veya Vergi No'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setValidatedTaxOrTcNum(false);

            } else {
                setValidatedTaxOrTcNum(true);
            }

            //addendumNo
            if (poolLog.addendumNo === "" || poolLog.addendumNo === undf) {

                tempNoti.push({ severity: 'error', summary: t('Zeyil No'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedAddendumNo(false);

            } else {
                setisValidatedAddendumNo(true);
            }

            //policyNumber
            if (poolLog.policyNumber === "" || poolLog.policyNumber === undf) {

                tempNoti.push({ severity: 'error', summary: t('Poliçe Numarası'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedPolicyNumber(false);

            } else {
                setisValidatedPolicyNumber(true);
            }

            debugger;
            // issueDate
            if (issueDate === null) {

                tempNoti.push({ severity: 'error', summary: t('Tanzim Tarihi'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedIssueDate(false);

            } else {
                poolLog.issueDate = issueDate;
                setisValidatedIssueDate(true);
            }

            // policyStartDate
            if (policyStartDate === null) {

                tempNoti.push({ severity: 'error', summary: t('Başlangıç Tarihi'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedPolicyStartDate(false);

            } else {

                poolLog.policyStartDate = policyStartDate;
                setisValidatedPolicyStartDate(true);
            }
            // policyEndDate
            if (policyEndDate === null) {

                tempNoti.push({ severity: 'error', summary: t('Bitiş Tarihi'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedPolicyEndDate(false);

            } else {
                poolLog.policyEndDate = policyEndDate;
                setisValidatedPolicyEndDate(true);
            }

            //grossPremium
            if (poolLog.grossPremium === "" || poolLog.grossPremium === undf) {

                tempNoti.push({ severity: 'error', summary: t('Brüt Prim'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedGrossPremium(false);

            } else {
                setisValidatedGrossPremium(true);
            }

            //tax
            if (poolLog.tax === "" || poolLog.tax === undf) {

                tempNoti.push({ severity: 'error', summary: t('Vergi Oranı'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedTax(false);

            } else {
                setisValidatedTax(true);
            }


            //selectCurrencyTypesValue
            if (selectCurrencyTypesValue === null || selectCurrencyTypesValue.name === "" || selectCurrencyTypesValue.name === 0 || selectCurrencyTypesValue.name === undf) {

                tempNoti.push({ severity: 'error', summary: t('Döviz Seçimi'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedSelectCurrencyTypesValue(false);

            } else {

                poolLog.currencyType = selectCurrencyTypesValue.name;
                poolLog.currencyTypeId = selectCurrencyTypesValue.code

                setisValidatedSelectCurrencyTypesValue(true);
            }

            //currencyRate
            if (poolLog.currencyRate === "" || poolLog.currencyRate === undf || poolLog.currencyRate === 0) {

                tempNoti.push({ severity: 'error', summary: t('Döviz Değeri'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedCurrencyRate(false);

            } else {
                setisValidatedCurrencyRate(true);
            }

            //selectInsuranceProductsValue
            if (selectInsuranceProductsValue === null || selectInsuranceProductsValue.name === "" || selectInsuranceProductsValue.name === 0 || selectInsuranceProductsValue.name === undf ||
                selectInsuranceProductsValue.productCode === "" || selectInsuranceProductsValue.productCode === 0 || selectInsuranceProductsValue.productCode === undf) {

                tempNoti.push({ severity: 'error', summary: t('Ürün Seçimi'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedSelectInsuranceProductsValue(false);

            } else {

                poolLog.insuranceProductId = selectInsuranceProductsValue.code;
                poolLog.insuranceProduct = {
                    id: selectInsuranceProductsValue.code,
                    name: selectInsuranceProductsValue.name
                }

                poolLog.insuranceProductCode = selectInsuranceProductsValue.productCode;
                poolLog.insuranceProductName = selectInsuranceProductsValue.name;

                setisValidatedSelectInsuranceProductsValue(true);
            }

            //incomingComm
            if (poolLog.incomingComm === "" || poolLog.incomingComm === undf || poolLog.incomingComm === 0) {

                tempNoti.push({ severity: 'error', summary: t('Gelen Komisyon Tutarı'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedIncomingComm(false);

            } else {
                setisValidatedIncomingComm(true);
            }

            //incomingComm
            if (poolLog.subAccountProductCommPer === "" || poolLog.subAccountProductCommPer === undf) {

                poolLog.subAccountProductCommPer = 0;
                // tempNoti.push({ severity: 'error', summary: t('Acente Komisyon Oranı'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedSubAccountProductCommPer(true);

            } else {
                setisValidatedSubAccountProductCommPer(true);
            }

            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }

        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${': ' + error.message}`, life: 5000 });

            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }
    //-------Validation(Check)
    const validForm2 = () => {
        //
        let tempNoti = [];
        try {

            //subAccountId
            if (selectManualSubAccountsValue === null) {

                tempNoti.push({ severity: 'error', summary: t('Kart Seçimi'), detail: t('Required'), life: 4000, toastId: 'code"' });

                setisValidatedInsuranceCompanyId(false);

            } else {
                setisValidatedInsuranceCompanyId(true);
            }


            if (tempNoti.length > 0) {
                toast.current.show(tempNoti);
                return false;

            } else {
                return true;
            }

        } catch (error) {
            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${': ' + error.message}`, life: 5000 });

            setIsSubmitted(false);
            if (tempNoti.length > 0) {
                toast.current.show(tempNoti)
            }
            tempNoti = []
            setError(error);

        }
        return false;

    }


    //PoolLog Clear
    const poolLog_Clear = () => {
        setSelectSubAccountsValue(null);
        setSelectManualSubAccountsValue(null);
        setSelectCreditCardValue(null);
        setSelectCurrencyTypesValue(null);
        setSelectInsuranceProductsValue(null);
        setIssueDate(null);
        setPolicyStartDate(null);
        setPolicyEndDate(null);
    }
    //Save--------
    const save = () => {
        debugger;
        try {

            let validStatus = validForm();
            setIsSubmitted(validStatus);
            if (validStatus) {

                calcPoolLog(poolLog);

                if (poolLog.id) {

                    updatePoolData();

                }
                else {

                    addPoolLogData();

                }

                setPoolLogSaveDialog(false);
                setPoolLog(poolLogsModel);
                poolLog_Clear();


            }

        } catch (error) {
            setPoolLog(poolLogsModel);

            error.message = error.message + '. UI:SaveOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogSaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }

    }
    const saveManualSubaccount = () => {
        debugger;
        let validStatus = validForm2();
        setIsSubmitted(validStatus);
        if (validStatus) {

            if (selectedLogs === null || selectedLogs.length === 0) {
                filter.poolIds = null;
            } else {
                filter.poolIds = selectedLogs.map((arr, i) => arr.id);
            }

            debugger;
            if (selectManualSubAccountsValue === null || selectManualSubAccountsValue === undefined) {
                filter.subAccountId = 0;
            }
            else {
                filter.subAccountId = selectManualSubAccountsValue.code;
            }


            if (filter.poolIds === null || filter.subAccountId === 0) {

                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogSaveError') + ': ' + error.message}`, life: 5000 });

            }
            else {

                addManualSubAccountData();

            }

            setPoolManualSubAccountSaveDialog(false);
            setPoolLog(poolLogsModel);
            poolLog_Clear();
        }


    }
    //Update----
    const updatePoolData = () => {
        try {

            let _poolLogs = [...poolLogs];
            let _poolLog = { ...poolLog };

            updatePool(poolLog)
                .then(data => {

                    _poolLogs = addToListByIndex(_poolLogs, _poolLog);
                    setPoolLogs(_poolLogs);
                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('PoolLogUpdateSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogUpdateError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })
            setPoolLog(poolLogsModel);


        } catch (error) {

            error.message = error.message + '.UI:UpdateOpr.';


            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogUpdateError') + ': ' + error.message}`, life: 5000 });
            setError(error);
        }
    }

    const calcPoolLog = (poolLog) => {

        try {

            let netPrim = 0;
            let hizmetBedeli = 0;
            let borc = 0;
            let sirketeKalan = 0;

            if (poolLog.tax === 0 || poolLog.tax === "" || poolLog.tax === undf) {
                netPrim = poolLog.grossPremium;
            }
            else {
                netPrim = (poolLog.grossPremium - ((poolLog.grossPremium / 100) * poolLog.tax));
            }

            //Acente komisyon
            hizmetBedeli = ((netPrim / 100) * poolLog.subAccountProductCommPer);
            borc = poolLog.grossPremium - hizmetBedeli;
            sirketeKalan = poolLog.incomingComm - hizmetBedeli;

            poolLog.netPremium = netPrim;
            poolLog.serviceFee = hizmetBedeli;
            poolLog.debt = borc;
            poolLog.companyComm = sirketeKalan;

            //Bu kısım kontrol edilecek.
            poolLog.issueDate = add12Hours(poolLog.issueDate);
            poolLog.policyStartDate = poolLog.policyStartDate;
            poolLog.policyEndDate = poolLog.policyEndDate;

        }
        catch (error) {
            error.message = error.message + '.UI:AddOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('calcPoolLog') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }

    //Add----
    const addPoolLogData = () => {
        try {

            addPoolLog(poolLog)
                .then(data => {

                })
                .catch(err => {
                    debugger;
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogAddError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })

            filterPools(filter)
                .then(data => { setPoolLogs(data.data); setTotalRecords(data.totalRecords) })
                .catch(err => {

                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogOnUploadError') + ': ' + err.response.data}`, life: 5000 });

                })
            toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('PoolLogAddSuccess')}`, life: 3000 });

            setPoolLog(poolLogsModel);


        } catch (error) {
            error.message = error.message + '.UI:AddOpr.';
            debugger;
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }

    //silme onayı btn----
    const deletePoolLogData = () => {
        try {

            let _poolLog = { ...poolLog };

            if (poolLogs.some(val => val.id === _poolLog.id)) {
                deletePool(_poolLog.id)
                    .then(data => {

                        filterPools(filter)
                            .then(data => { setPoolLogs(data.data); setTotalRecords(data.totalRecords) })
                            .catch(err => {

                                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogOnUploadError') + ': ' + err.response.data}`, life: 5000 });

                            })

                        // getPoolList()
                        //     .then(data => setPoolLogs(data))
                        //     .catch(err => {
                        //         //throw new Error(`${err.data}`);
                        //         toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogDeleteError') + ': ' + err.data}`, life: 5000 });

                        //     })
                        // toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('PoolLogDeleteSuccess')}`, life: 3000 });
                    })
                    .catch(err => {
                        setPoolLog(poolLogsModel);
                        toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogDeleteError') + ': ' + err.data}`, life: 5000 });

                        //throw new Error(`${err.data}`);
                    })

            }

            setPoolLog(poolLogsModel);
            setPoolLogDeleteDialog(false);

        } catch (error) {


            error.message = error.message + '.UI:DeleteOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogDeleteError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }

    const onUpload = (event) => {
        try {
            debugger;
            toggleProgressBar(true);
            uploadExcelPoolLog(excelFile)
                .then(data => {

                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: 'PoolLogUploadSuccess', life: 5000 });

                    filterPools(filter)
                        .then(data => { setPoolLogs(data.data); setTotalRecords(data.totalRecords) })
                        .catch(err => {

                            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogOnUploadError') + ': ' + err.response.data}`, life: 5000 });

                        })
                    toggleProgressBar(false);
                })
                .catch(err => {

                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogOnUploadError') + ': ' + err.response.data}`, life: 20000 });
                    toggleProgressBar(false);
                    //throw new Error(`${err.data}`);
                })

        } catch (error) {

            error.message = error.message + '.  UI:OnUpload-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogOnUploadError') + ': ' + error.message}`, life: 20000 });

            setError(error);
        }
    }

    const onSelectUpload = (e) => {
        try {
            debugger;

            const val = (e.files.length > 0 && e.files[0]) || '';
            let _excelFile = { ...excelFile };
            _excelFile[`formFile`] = val;

            setExcelFile(_excelFile);

            toast.current.show({ severity: 'info', summary: `${t('success')}`, detail: 'PoolLogSelectUploadSuccess', life: 5000 });

        } catch (error) {


            error.message = error.message + '.UI:SelectUploadOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogSelectUploadError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }
    //Add----
    const addManualSubAccountData = () => {
        try {

            updateSubAccountManual(filter)
                .then(data => {
                    filterPools(filter)
                        .then(data => { setPoolLogs(data.data); setTotalRecords(data.totalRecords) })
                        .catch(err => {

                            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogOnUploadError') + ': ' + err.response.data}`, life: 5000 });

                        })

                    toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('PoolLogAddSuccess')}`, life: 3000 });
                })
                .catch(err => {
                    debugger;
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogAddError') + ': ' + err.response.data}`, life: 5000 });

                    //throw new Error(`${err.data}`);
                })

            setPoolLog(poolLogsModel);


        } catch (error) {
            error.message = error.message + '.UI:AddOpr.';
            debugger;
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogAddError') + ': ' + error.message}`, life: 5000 });
            setError(error);

        }
    }


    const toggleProgressBar = (showProgressBar) => {
        setShowProgressBar(showProgressBar);
    };


    const sendSelectedLogs = () => {
        ////debugger;
        //  let _products = products.filter(val => !selectedLogss.includes(val));
        //  setLogs(_products);
        setSendLogsDialog(false);
        //setSelectedLogs(null);
        //console.log(selectedLogs);

        let selectedLogsIds = selectedLogs.map((arr, i) => arr.id);
        sendLogsToDebt(selectedLogsIds)
            .then(data => {
                filterPools(filter)
                    .then(data => { setPoolLogs(data.data); setTotalRecords(data.totalRecords) })
                    .catch(err => {

                        toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogOnUploadError') + ': ' + err.response.data}`, life: 20000 });

                    })

                toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('sendLogsToDebtSuccess')}`, life: 3000 });
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Logs sended', life: 3000 });

            })
            .catch(err => {
                //debugger;

                setSelectedLogs(null);
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('sendLogsToDebtError') + ': ' + err.response.data}`, life: 20000 });
                //throw new Error(`${err.data}`);
            })



        setSelectedLogs(null);
    }

    const sendDoubleSelectedLogs = () => {

        setSendDoubleLogsDialog(false);

        let selectedLogsIds = selectedLogs.map((arr, i) => arr.id);
        sendDoubleLogsToDebt(selectedLogsIds, true)
            .then(data => {
                filterPools(filter)
                    .then(data => { setPoolLogs(data.data); setTotalRecords(data.totalRecords) })
                    .catch(err => {

                        toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogOnUploadError') + ': ' + err.response.data}`, life: 5000 });

                    })

                toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('sendDoubleLogsToDebtSuccess')}`, life: 3000 });
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Logs sended', life: 3000 });

            })
            .catch(err => {
                //debugger;

                setSelectedLogs(null);
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('sendDoubleLogsToDebtError') + ': ' + err.response.data}`, life: 5000 });
                //throw new Error(`${err.data}`);
            })



        setSelectedLogs(null);
    }

    const SendFindDataLogs = () => {
        debugger;
        //  let _products = products.filter(val => !selectedLogss.includes(val));
        //  setLogs(_products);
        setFindDataLogsDialog(false);
        //setSelectedLogs(null);
        //console.log(selectedLogs);

        let selectedLogsIds = selectedLogs.map((arr, i) => arr.id);
        sendFindDataLogs(selectedLogsIds)
            .then(data => {

                filterPools(filter)
                    .then(data => { setPoolLogs(data.data); setTotalRecords(data.totalRecords) })
                    .catch(err => {
                        toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('FiltreListGettingError') + ': ' + err.response.data}`, life: 5000 });
                    })

                toast.current.show({ severity: 'success', summary: `${t('success')}`, detail: `${t('sendLogsToDebtSuccess')}`, life: 3000 });

            })
            .catch(err => {

                setSelectedLogs(null);
                toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('sendLogsToDebtError') + ': ' + err.response.data}`, life: 5000 });
                //throw new Error(`${err.data}`);
            })

        setSelectedLogs(null);
    }

    const sendOAData = () => {
        debugger;
        toggleProgressBar(true);
        setSendOASendDataDialog(false);

        updateSubaccountAllOfPools()
            .then(data => {

            })
            .catch(err => {
                debugger;
                setSelectedLogs(null);
                const messagesArray = err.response.data.split(',');
                setMessageOA(messagesArray);
            })

        toggleProgressBar(false);

        filtre();

        setSelectedLogs(null);
    }

    const onPageChange = (event) => {
        //debugger;
        setFirst(event.first);
        setRows(event.rows);
        filter.pageNumber = event.page + 1;
        filter.pageSize = event.rows;
        filtre();
    };
    const convertDate = (value) => {

        let newDate = new Date()
        newDate = value;
        let date = ("0" + newDate.getDate()).slice(-2);
        let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
        let year = newDate.getFullYear("yyyy");
        let thisDay = year + "-" + month + "-" + date;

        return thisDay;
    }
    const filtre = () => {

        debugger;
        if (selectSubAccountsValue === null || selectSubAccountsValue === undefined) {
            filter.subAccountId = 0;
        }
        else {
            filter.subAccountId = selectSubAccountsValue.code;
        }

        if (filterStartDate !== null) {
            filter.startDate = convertDate(filterStartDate);
        }
        else {
            filter.startDate = "0001-01-01T00:00:00";
        }

        if (filterEndDate !== null) {
            filter.endDate = convertDate(filterEndDate);
        }
        else {
            filter.endDate = "0001-01-01T00:00:00";
        }

        if (selectCreditCardValue === null || selectCreditCardValue.length === 0) {
            filter.creditCardsList = null;
        } else {
            filter.creditCardsList = selectCreditCardValue.map((arr, i) => arr.code);
        }

        filter.showCreditCard = filterCreditCardChecked;

        try {
            toggleProgressBar(true);
            filterPools(filter)
                .then(data => { setPoolLogs(data.data); setTotalRecords(data.totalRecords); toggleProgressBar(false); })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('FiltreListGettingError') + ': ' + err.response.data}`, life: 5000 });
                    toggleProgressBar(false);
                })
        } catch (error) {
            setPoolLog(poolLogsModel);

            error.message = error.message + '. UI:SaveOpr.';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogSaveError') + ': ' + error.message}`, life: 5000 });
            setError(error);
            toggleProgressBar(false);
        }

    }

    //**************************************
    //Logic fonks. START
    //**************************************

    const dataTypeBodyTemplate1 = (rowData) => {
        // 1	Excel
        // 2	API
        // 3	User 

        let aa = "";
        if (rowData.dataInsertTypeId === 1) {
            aa = "Excel"
        }
        else if (rowData.dataInsertTypeId === 2) {
            aa = "API"
        }
        else if (rowData.dataInsertTypeId === 3) {
            aa = "User"
        }

        return aa;
    }

    const dataBodyLenght = (rowdata) => {
        if (rowdata.subAccount === undefined) {
            return '-'
        } else {
            return rowdata.subAccount.name.length > 15 ? rowdata.subAccount.name.substring(0, 20) + '...' : rowdata.subAccount.name
        }
    }
    const dateBodyTemplate1 = (rowData) => {

        return formatDate(rowData.createdDate);
    }
    const dateBodyTemplate2 = (rowData) => {

        return formatDate(rowData.issueDate);
    }
    const dateBodyTemplate3 = (rowData) => {

        return formatDate(rowData.policyStartDate);
    }
    const dateBodyTemplate4 = (rowData) => {

        return formatDate(rowData.policyEndDate);
    }

    const grossCurrency = (rowData) => {

        return formatCurrency(rowData.grossPremium, rowData.currencyType);
    }
    const netCurrency = (rowData) => {

        return formatCurrency(rowData.netPremium, rowData.currencyType);
    }
    const incomingCurrency = (rowData) => {

        return formatCurrency(rowData.incomingComm, rowData.currencyType);
    }
    const serviceFeeCurrency = (rowData) => {

        return formatCurrency(rowData.serviceFee, rowData.currencyType);
    }
    const debtCurrency = (rowData) => {

        return formatCurrency(rowData.debt, rowData.currencyType);
    }
    const companyCommCurrency = (rowData) => {

        return formatCurrency(rowData.companyComm, rowData.currencyType);
    }



    function add12Hours(date) {
        let newDate = new Date(date);
        newDate.setHours(newDate.getHours() + 12);
        return newDate;
    }


    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    const addToListByIndex = (list, item) => {

        try {
            if (findIndexById(item.id) >= 0) {

                const index = findIndexById(item.id);
                list[index] = item;

            } else {

                list.push(item);
            }

            return list;


        } catch (error) {

            error.message = error.message + '. UI:ByIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${' ' + error.message}`, life: 5000 });
            setError(error);

        }

    }

    const findIndexById = (id) => {
        try {
            let index = -1;
            for (let i = 0; i < poolLogs.length; i++) {
                if (poolLogs[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;

        } catch (error) {

            error.message = error.message + '. UI:FindIndexOpr.';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    //**************************************
    //Logic fonks. END
    //**************************************




    //**************************************
    //btn fonks. START
    //**************************************


    //gorup adı eklem btn---
    const openNew = () => {
        try {
            setPoolLog(poolLogsModel);
            setSubmitted(false);
            setPoolLogSaveDialog(true);
            poolLog_Clear();

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${': ' + error.message}`, life: 5000 });
            setPoolLog(poolLogsModel);
            setSubmitted(false);
            setError(error);
        }
    }


    const openNew2 = () => {
        try {
            setPoolLog(poolLogsModel);
            setSubmitted(false);
            setPoolManualSubAccountSaveDialog(true);
            poolLog_Clear();

        } catch (error) {
            error.message = error.message + '. UI:openModel';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${': ' + error.message}`, life: 5000 });
            setPoolLog(poolLogsModel);
            setSubmitted(false);
            setError(error);
        }
    }


    //gorup adı güncelleme btn------
    const editPool = (poolLog) => {
        try {
            ////debugger;
            let _poolLog = { ...poolLog };
            setSubmitted(false);

            getPoolById(_poolLog.id)
                .then(data => {

                    for (let index = 0; index < subAccounts.length; index++) {
                        const element = subAccounts[index];

                        if (element.code === data.subAccountId) {
                            setSelectSubAccountsValue({ name: element.name, code: parseInt(element.code) });
                            if (data.customerTypeId === 1) {
                                setCustomerTypeTcShow('show field col-2');
                                setCustomerTypeTaxShow('hidden field col-2');
                            }
                            else if (data.customerTypeId === 2) {
                                setCustomerTypeTaxShow('show field col-2');
                                setCustomerTypeTcShow('hidden field col-2');
                            }
                        }
                    }

                    for (let index = 0; index < currencyTypes.length; index++) {
                        const elementA = currencyTypes[index];

                        if (elementA.name === data.currencyType) {
                            setSelectCurrencyTypesValue({ name: elementA.name, code: parseInt(elementA.code) });
                        }
                    }
                    //ebugger;
                    for (let index = 0; index < insuranceProducts.length; index++) {
                        const elementB = insuranceProducts[index];

                        if (elementB.productCode === data.insuranceProductCode) {
                            setSelectInsuranceProductsValue({ name: elementB.name, code: parseInt(elementB.code), productCode: elementB.productCode });
                        }
                    }

                    data.issueDate = new Date(data.issueDate);
                    data.policyStartDate = new Date(data.policyStartDate);
                    data.policyEndDate = new Date(data.policyEndDate);
                    setIssueDate(data.issueDate);
                    setPolicyStartDate(data.policyStartDate);
                    setPolicyEndDate(data.policyEndDate);


                    getProductProportionsListBySubAccountId(data.subAccountId)
                        .then(data => {
                            setProductProportions(data);
                        })
                        .catch(err => {
                            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('KartBulanamadı.') + ': ' + err.data}`, life: 5000 });
                        })

                    setPoolLog(data);
                })
                .catch(err => {
                    //
                    setPoolLog(poolLogsModel);
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogUpdatedError') + ': ' + err.data}`, life: 5000 });

                    //throw new Error(`${err.response.data}`);
                })

            setPoolLogSaveDialog(true);
        } catch (error) {

            error.message = error.message + '.  UI:EditeModel..';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('PoolLogUpdatedError') + ': ' + error.message}`, life: 5000 });

            setError(error);
        }
    }

    const confirmDeletePoolLog = (poolLog) => {
        try {

            setPoolLog(poolLog);
            setPoolLogDeleteDialog(true);

        } catch (error) {
            error.message = error.message + '.  UI:ConfirmModel..';

            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${' ' + error.message}`, life: 5000 });

            setPoolLog(poolLogsModel);
            setError(error);
        }

    }

    const hideDeleteDialog = () => {
        try {
            setPoolLog(poolLogsModel);
            setSubmitted(false);
            setPoolLogDeleteDialog(false);

        } catch (error) {
            error.message = error.message + '.  UI:DeletePoolLog-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //Ekleme-güncelleme modali kapatma btn-----
    const hideSaveDialog = () => {
        try {

            setPoolLog(poolLogsModel);
            setSubmitted(false);
            setPoolLogSaveDialog(false);
            poolLog_Clear();

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }
    const hideSaveManualSubAccountDialog = () => {
        try {

            setPoolLog(poolLogsModel);
            setSubmitted(false);
            setPoolManualSubAccountSaveDialog(false);
            poolLog_Clear();

        } catch (error) {
            error.message = error.message + '.  UI:hideModal..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const hideSendLogsDialog = () => {
        setSendLogsDialog(false);
    }
    const hideSendDoubleLogsDialog = () => {
        setSendDoubleLogsDialog(false);
    }
    const hideFindDataLogsDialog = () => {
        setFindDataLogsDialog(false);
    }
    const hideSendOASendDataDialog = () => {
        setSendOASendDataDialog(false);
    }

    //**------
    const onInputChange = (e, name) => {
        try {
            const val = (e.target && e.target.value) || '';
            let _poolLog = { ...poolLog };
            _poolLog[`${name}`] = val;

            setPoolLog(_poolLog);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }


    const onInputIssueDateChange = (e, name) => {
        debugger;
        try {
            const val = (e.target && e.target.value) || '';
            let _poolLog = { ...poolLog };
            _poolLog[`${name}`] = val;

            if (val === "") {
                setIsInputDisabled(true);
                setSelectCurrencyTypesValue(null);
                _poolLog.currencyTypesId = 0;
                _poolLog.currencyRate = 0;
            }
            else {
                setIsInputDisabled(false);
                setSelectCurrencyTypesValue(null);
                _poolLog.currencyTypesId = 0;
                _poolLog.currencyRate = 0;

                getCurrencyTypesList()
                    .then(data => {
                        setCurrencyTypes(data.map(function (item, i) {
                            return { name: item.name, code: parseInt(item.id) };
                        }));
                    })
                    .catch(err => {
                        toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('CurrencyTypesListGettingError') + ': ' + err.data}`, life: 5000 });
                    })
            }

            setIssueDate(e.value);
            setPoolLog(_poolLog);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const onInputFilterChange = (e, name) => {
        try {
            const val = (e.target && e.target.value) || '';
            let _filter = { ...filter };
            _filter[`${name}`] = val;

            setFilter(_filter);

        } catch (error) {
            error.message = error.message + '.  UI:InputFilterChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }

    }

    const onSubAccountChange = (e, name) => {
        //debugger;
        try {

            const val = (e.target && e.target.value) || '';
            let subAccountTaxNumber = '';
            let subAccountTcNumber = '';

            setSelectSubAccountsValue(e.value)
            //apiden currency'i çek. | currencyRate
            getSubAccountById(val.code)
                .then(data => {
                    let _poolLog = { ...poolLog };

                    _poolLog[`${'customerTypeId'}`] = data.customerTypeId;

                    if (data.customerTypeId === 1) {

                        subAccountTcNumber = data.tc
                        _poolLog[`${'subAccountTcNumber'}`] = subAccountTcNumber;
                        _poolLog[`${'subAccountTaxNumber'}`] = '';
                        setCustomerTypeTcShow('show field col-2');
                        setCustomerTypeTaxShow('hidden field col-2');
                    }
                    else if (data.customerTypeId === 2) {

                        subAccountTaxNumber = data.taxNo
                        _poolLog[`${'subAccountTaxNumber'}`] = subAccountTaxNumber;
                        _poolLog[`${'subAccountTcNumber'}`] = '';
                        setCustomerTypeTcShow('hidden field col-2');
                        setCustomerTypeTaxShow('show field col-2');
                    }

                    setPoolLog(_poolLog);
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('GetSubAccountByIdError') + ': ' + err.data}`, life: 5000 });
                })

            getProductProportionsListBySubAccountId(val.code)
                .then(data => {
                    setProductProportions(data);
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('GetProductProportionsListBySubAccountIdError') + ': ' + err.data}`, life: 5000 });
                })

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }
    const onManualSubAccountChange = (e, name) => {
        //debugger;
        try {

            const val = (e.target && e.target.value) || '';


            setSelectManualSubAccountsValue(e.value)
            //apiden currency'i çek. | currencyRate


        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }
    const onInsuranceProductsChange = (e, name) => {

        try {
            ////debugger;
            const val = (e.target && e.target.value) || '';
            let subAccountProductCommPer = '';

            setSelectInsuranceProductsValue(e.value)

            //currencyType 1 TL
            //currencyType 2 USD
            //currencyType 3 EUR

            var aaa = productProportions.find(x => x.insuranceProductId === val.code && x.currencyTypeId === poolLog.currencyTypeId);
            if (aaa !== undf) {
                subAccountProductCommPer = aaa.amount;
            }
            else {
                subAccountProductCommPer = 0;
            }

            let _poolLog = { ...poolLog };
            _poolLog[`${'subAccountProductCommPer'}`] = subAccountProductCommPer;

            setPoolLog(_poolLog);

        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    const onCurrencyChange = (e, name) => {
        debugger;
        try {

            const val = (e.target && e.target.value) || '';
            let currencyValue = '';
            let currencyTypeId = '';


            let newDate = new Date()
            newDate = issueDate;
            if (newDate === null || newDate === "" || newDate === undf) {
                throw new Exception('Tanzim tarihi seçmelisiniz.');
            }

            let date = newDate.getDate();
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            let thisDay = year + "-" + month + "-" + date;


            setSelectCurrencyTypesValue(e.value);
            setSelectInsuranceProductsValue(null);
            if (val.code === 2 || val.code === 3) {

                //apiden currency'i çek. | currencyRate
                getCurrenciesByDate(thisDay, val.code)
                    .then(data => {

                        currencyValue = data.rate;
                        currencyTypeId = val.code;

                        let _poolLog = { ...poolLog };
                        _poolLog[`${'currencyRate'}`] = currencyValue;
                        _poolLog[`${'currencyTypeId'}`] = currencyTypeId;
                        _poolLog.subAccountProductCommPer = 0;
                        setPoolLog(_poolLog);
                    })
                    .catch(err => {
                        toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${t('CurrencyTypesListGettingError') + ': ' + err.data}`, life: 5000 });
                    })

            }
            else {
                let _poolLog = { ...poolLog };
                _poolLog[`${'currencyRate'}`] = 1;
                _poolLog[`${'currencyTypeId'}`] = 1;
                _poolLog.subAccountProductCommPer = 0;
                setPoolLog(_poolLog);
            }


        } catch (error) {
            error.message = error.message + '.  UI:InputChange-Opr..';
            toast.current.show({ severity: 'error', summary: `${t('Error')}`, detail: `${error.message}`, life: 5000 });

            setError(error);
        }
    }

    //**************************************
    //btn fonks. END
    //**********************



    const saveDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const saveDialogManualSubaccountFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideSaveManualSubAccountDialog} />
            <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={saveManualSubaccount} />
        </>
    );
    const deletePoolLogDialogFooter = (
        <>
            <Button label={t('Cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label={t('Delete')} icon="pi pi-check" className="p-button-text" onClick={deletePoolLogData} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('PoolLogList')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('Filter')} />
            </span>
        </div>
    );

    const verifiedBodyTemplate = (rowData) => {

        let falseOrTrue = "";
        if (rowData.insuranceProduct === undf || rowData.insuranceProduct === null || rowData.insuranceProduct.elementer === false) {
            falseOrTrue = "text-pink-500 pi-times-circle";
        }
        else {
            falseOrTrue = "text-green-500 pi-check-circle"
        }

        return <i className={classNames('pi ' + falseOrTrue)}></i>;
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateUserClaimCommand')) {
                            return (
                                <Button icon="pi pi-pencil" title={t('Update')} className="p-button-rounded p-button-success" onClick={() => editPool(rowData)} />
                            )
                        }

                        return null;
                    })()}

                </div>
            </>
        );
    }
    const actionBodyTemplate2 = (rowData) => {
        return (
            <>
                <div className="actions">

                    {(() => {
                        if (JwtHelper.isRoleExist('UpdateUserClaimCommand')) {
                            return (
                                <Button icon="pi pi-trash" title={t('Delete')} className="p-button-rounded p-button-danger" onClick={() => confirmDeletePoolLog(rowData)} />
                            )
                        }

                        return null;
                    })()}
                </div>
            </>
        );
    }

    const confirmSendDoubleSelectedLogs = () => {
        setSendDoubleLogsDialog(true);
    }

    const confirmSendSelectedLogs = () => {
        setSendLogsDialog(true);
    }

    const confirmFindDataLogsDialog = () => {
        setFindDataLogsDialog(true);
    }

    const confirmOASendData = () => {
        setSendOASendDataDialog(true);
    }

    const items = [
        {
            label: t('FindSubAccounts'),
            icon: 'pi pi-sliders-v',
            disabled: !selectedLogs || !selectedLogs.length,
            command: () => {
                confirmFindDataLogsDialog()
            }
        },
        {
            label: t('SendDebt'),
            icon: 'pi pi-send',
            disabled: !selectedLogs || !selectedLogs.length,
            command: () => {
                confirmSendSelectedLogs()
            }
        },
        {
            label: t('SendDebtandCredit'),
            icon: 'pi pi-send',
            disabled: !selectedLogs || !selectedLogs.length,
            command: () => {
                confirmSendDoubleSelectedLogs()
            }
        },
        {
            label: t('OA ile Gönder'),
            icon: 'pi pi-send',
            command: () => {
                confirmOASendData()
            }
        }

    ];

    const leftToolbarTemplate = () => {


        return (
            <>
                <Button label={t('Filtrele')} icon="pi pi-filter" className="p-button-warning mr-2" onClick={filtre} />
            </>
        );

    }

    const centerToolbarFiltring = () => {
        return (
            <>
                {(() => {

                    return (
                        <div>

                            <SplitButton label={'Poliçe Ekle'} icon="pi pi-plus" onClick={openNew} model={items} severity="success" className="p-button-success mr-2" />
                            <Button label={'Seçilenleri Ata'} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew2} disabled={!selectedLogs || !selectedLogs.length} />
                            <React.Fragment>
                                <FileUpload className="p-button" mode="basic"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onSelect={(e) => onSelectUpload(e)}
                                    maxFileSize={10000000}
                                    label={t('ImportExcel')}
                                    chooseLabel={t('ImportExcel')}
                                    url=""
                                    onBeforeUpload={onUpload}

                                />
                            </React.Fragment>
                            <Toast ref={toast}></Toast>



                            <hr></hr>
                            <div className="my-2" >

                                <Dropdown className="col-md-4 mr-2" showClear filter id="filterSubAccountId" placeholder="Acente Kartı Seçin" value={selectSubAccountsValue} options={subAccountsforFilter} onChange={(e) => setSelectSubAccountsValue(e.value)} optionLabel="name" />

                                <InputText className="mr-2" id="filterSearchKey" placeholder="Aranacak Kelime" value={filter.search} onChange={(e) => onInputFilterChange(e, 'search')} />
                                <Checkbox className="col-md-4 mr-2" onChange={e => setFilterCreditCardChecked(e.checked)} checked={filterCreditCardChecked}></Checkbox>

                                <MultiSelect className="col-md-4 mr-2" value={selectCreditCardValue} onChange={(e) => setSelectCreditCardValue(e.value)} options={creditCardFilter} optionLabel="name" filter placeholder="Kredi Kartları" maxSelectedLabels={3} />

                                <Calendar className="mr-2" id="filterStartDate" placeholder="Başlangıç Tarihi" locale="tr" dateFormat="dd.mm.yy" value={filterStartDate} onChange={(e) => setFilterStartDate(e.value)} />

                                <Calendar className="mr-2" id="filterEndDate" placeholder="Bitiş Tarihi" locale="tr" dateFormat="dd.mm.yy" value={filterEndDate} onChange={(e) => setFilterEndDate(e.value)} />


                            </div>

                        </div >
                    )

                })()}
            </>
        );

    }

    // const rightToolbarTemplate2 = () => {
    //     return (
    //         <div className="formgrid grid">
    //             <div className="field col-3">
    //             </div>
    //         </div>
    //     )
    // }

    const formatCurrency = (value, currencyType) => {
        if (value === undefined) {
            return 0;
        }

        return value.toLocaleString('tr-TR', { style: 'currency', currency: currencyType }).replace(',', '.');
    };

    const sendLogsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideSendLogsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={sendSelectedLogs} />
        </>
    );

    const sendDoubleLogsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideSendDoubleLogsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={sendDoubleSelectedLogs} />
        </>
    );

    const findDataLogsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideFindDataLogsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={SendFindDataLogs} />
        </>
    );

    const sendOASendDataDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideSendOASendDataDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={sendOAData} />
        </>
    );

    const pageBottomButtons = {
        layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };


    if (error) {
        // toast.current.show({ severity: 'error', summary: 'Hata', detail: `${error}`, life: 5000 });

        return (<div className="layout-main">

            <ErrorBoundary>
                {error}
            </ErrorBoundary>

        </div>)
    }

    return (


        <div className="grid crud-demo">
            <div className="col-12">

                <div className="card">
                    <div className="surface-section md:px-3 lg:px-4">

                        <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                            <div>

                                <ul className="list-none p-0 m-0 flex align-items-center font-medium">
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer"> {t('PoolLog')}</span>
                                    </li>
                                    <li className="px-2">
                                        |
                                    </li>
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">{t('HomePage')} </span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="text-500 no-underline line-height-3 cursor-pointer">{t('PoolLog')}</span>
                                    </li>
                                    <li className="px-2">
                                        <i className="pi pi-angle-right text-500 line-height-3"></i>
                                    </li>
                                    <li>
                                        <span className="font-medium text-900">{t('PoolLogList')}</span>
                                    </li>
                                </ul>

                            </div>
                            <div className="mt-2">

                                <DbCurrencies />

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="card">
                        <Toast ref={toast} />
                        <Panel header={leftToolbarTemplate} toggleable collapsed="true" collapseIcon="pi pi-angle-up" expandIcon="pi pi-angle-down">
                            <div>{centerToolbarFiltring()}</div>
                        </Panel>

                        {showProgressBar && <ProgressBarDiv />}

                        {/* <Toolbar className="mb-4" left={rightToolbarTemplate} ></Toolbar> */}
                        <DataTable ref={dt} value={poolLogs} responsiveLayout="scroll" scrollable scrollHeight="620px" selection={selectedLogs} onSelectionChange={(e) => setSelectedLogs(e.value)}
                            dataKey="id" className="datatable-responsive"
                            globalFilter={globalFilter} emptyMessage={t('NoRecordsFound')} header={header}>
                            <Column selectionMode="multiple" ></Column>
                            <Column field="id" header={t('Id')} sortable></Column>
                            <Column field="dataInsertTypeId" header={t('InsertType')} sortable style={{ minWidth: '100px' }} body={dataTypeBodyTemplate1}></Column>
                            <Column field="createdDate" header={t('CreatedDate')} sortable style={{ minWidth: '100px' }} body={dateBodyTemplate1}></Column>
                            <Column field="lastUser" header={t('LastUser')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="insuranceCompanyAccountNo" header={t('PartajNo')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="insuranceProduct.insuranceCompany.name" header={t('Company')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="subAccount.name" header={t('SubAccountName')} sortable style={{ minWidth: '200px' }} body={dataBodyLenght} ></Column>
                            <Column field="subAccountTaxNumber" header={t('TaxNo')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="subAccountTcNumber" header={t('TcNo')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="insuranceByPerson" header={t('InsuranceByPerson')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="insuredPersonName" header={t('InsuredPersonName')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="insuredPersonTc" header={t('InsuredPersonTc')} sortable style={{ minWidth: '100px' }} ></Column>
                            <Column field="policyNumber" header={t('PolicyNumber')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="addendumNo" header={t('AddendumNo')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="description" header={t('Description')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="creditCard" header={t('CreditCard')} sortable style={{ minWidth: '200px' }} ></Column>
                            <Column field="issueDate" header={t('IssueDate')} sortable style={{ minWidth: '100px' }} body={dateBodyTemplate2} ></Column>
                            <Column field="policyStartDate" header={t('PolicyStartDate')} sortable style={{ minWidth: '100px' }} body={dateBodyTemplate3}></Column>
                            <Column field="policyEndDate" header={t('PolicyEndDate')} sortable style={{ minWidth: '100px' }} body={dateBodyTemplate4}></Column>
                            <Column field="tax" header={t('Tax')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="currencyType" header={t('CurrencyType')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="currencyRate" header={t('CurrencyRate')} sortable style={{ minWidth: '100px' }}></Column>
                            <Column field="insuranceProduct.name" header={t('InsuranceProductName')} sortable style={{ minWidth: '100px' }} ></Column>
                            <Column field="insuranceProductCode" header={t('InsuranceProductCode')} sortable style={{ minWidth: '100px' }} ></Column>
                            <Column field="subAccountProductCommPer" header={t('SubAccountProductCommPer')} sortable style={{ minWidth: '100px' }} ></Column>
                            <Column field="grossPremium" header={t('GrossPremium')} sortable style={{ minWidth: '100px' }} body={grossCurrency}></Column>
                            <Column field="netPremium" header={t('NetPremium')} sortable style={{ minWidth: '100px' }} body={netCurrency}></Column>
                            <Column field="incomingComm" header={t('IncomingComm')} sortable style={{ minWidth: '100px' }} body={incomingCurrency}></Column>
                            <Column field="serviceFee" header={t('ServiceFee')} sortable style={{ minWidth: '100px' }} body={serviceFeeCurrency}></Column>
                            <Column field="debt" header={t('Debt')} sortable style={{ minWidth: '100px' }} body={debtCurrency}></Column>
                            <Column field="companyComm" header={t('CompanyComm')} sortable style={{ minWidth: '100px' }} body={companyCommCurrency}></Column>
                            <Column field="elementer" header={t('Elementer')} dataType="boolean" bodyClassName="text-center" body={verifiedBodyTemplate}></Column>
                            <Column header="#" body={actionBodyTemplate} ></Column>
                            <Column header="#" body={actionBodyTemplate2} ></Column>
                        </DataTable>
                        <Paginator template={pageBottomButtons} first={first} rows={rows} totalRecords={totalRecords} rowsPerPageOptions={[100, 500, 1000]} onPageChange={onPageChange} />

                        <Dialog visible={poolManualSubAccountSaveDialog} style={{ width: '850px' }} header={t('PoolLogManuel')} modal className="p-fluid" footer={saveDialogManualSubaccountFooter} onHide={hideSaveManualSubAccountDialog}>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="subAccountId">{t('SubAccountName')} </label>
                                    <Dropdown id="subAccountId" value={selectManualSubAccountsValue} filter options={subAccounts} onChange={(e) => onManualSubAccountChange(e, 'subAccountId')} optionLabel="name" placeholder="Sigorta Şirketi" className={classNames({ 'p-invalid': !isValidatedSelectSubAccountsValue })} />
                                </div>
                            </div>
                        </Dialog>
                        <Dialog visible={poolLogSaveDialog} style={{ width: '850px' }} header={t('PoolLogForm')} modal className="p-fluid" footer={saveDialogFooter} onHide={hideSaveDialog}>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="subAccountId">{t('SubAccountName')} </label>
                                    <Dropdown id="subAccountId" value={selectSubAccountsValue} filter options={subAccounts} onChange={(e) => onSubAccountChange(e, 'subAccountId')} optionLabel="name" placeholder="Sigorta Şirketi" className={classNames({ 'p-invalid': !isValidatedSelectSubAccountsValue })} />
                                </div>
                                <div className={customerTypeTaxShow}>
                                    <label htmlFor="subAccountTaxNumber">{t('TaxNo')} </label>
                                    <InputText id="subAccountTaxNumber" value={poolLog.subAccountTaxNumber} onChange={(e) => onInputChange(e, 'subAccountTaxNumber')} required autoFocus className={classNames({ 'p-invalid': !isValidatedTaxOrTcNum })} />
                                    {submitted && !poolLog.subAccountTaxNumber && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className={customerTypeTcShow}>
                                    <label htmlFor="subAccountTcNumber">{t('TCIdentificationNumber')} </label>
                                    <InputText id="subAccountTcNumber" value={poolLog.subAccountTcNumber} onChange={(e) => onInputChange(e, 'subAccountTcNumber')} required autoFocus className={classNames({ 'p-invalid': !isValidatedName })} />
                                    {submitted && !poolLog.subAccountTcNumber && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="addendumNo">{t('AddendumNo')} </label>
                                    <InputText id="addendumNo" value={poolLog.addendumNo} onChange={(e) => onInputChange(e, 'addendumNo')} required autoFocus className={classNames({ 'p-invalid': !isValidatedAddendumNo })} />
                                    {submitted && !poolLog.addendumNo && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className="field col-3">
                                    <label htmlFor="insuranceCompanyAccountNo">{t('PartajNo')}  </label>
                                    <InputText id="insuranceCompanyAccountNo" value={poolLog.insuranceCompanyAccountNo} onChange={(e) => onInputChange(e, 'insuranceCompanyAccountNo')} required />
                                    {submitted && !poolLog.insuranceCompanyAccountNo && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-3">
                                    <label htmlFor="policyNumber">{t('PolicyNumber')}  </label>
                                    <InputText id="policyNumber" value={poolLog.policyNumber} onChange={(e) => onInputChange(e, 'policyNumber')} required className={classNames({ 'p-invalid': !isValidatedPolicyNumber })} />
                                    {submitted && !poolLog.policyNumber && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className="field col-3">
                                    <label htmlFor="issueDate">{t('IssueDate')}</label>
                                    <Calendar id="issueDate" locale="tr" dateFormat="dd.mm.yy" value={poolLog.issueDate} onChange={(e) => onInputIssueDateChange(e, 'issueDate')} required className={classNames({ 'p-invalid': !isValidatedIssueDate })} />
                                </div>
                                <div className="field col-3">
                                    <label htmlFor="policyStartDate">{t('PolicyStartDate')}</label>
                                    <Calendar id="policyStartDate" locale="tr" dateFormat="dd.mm.yy" value={poolLog.policyStartDate} onChange={(e) => setPolicyStartDate(e.value)} required className={classNames({ 'p-invalid': !isValidatedPolicyStartDate })} />
                                </div>
                                <div className="field col-3">
                                    <label htmlFor="policyEndDate">{t('PolicyEndDate')}</label>
                                    <Calendar id="policyEndDate" locale="tr" dateFormat="dd.mm.yy" value={poolLog.policyEndDate} onChange={(e) => setPolicyEndDate(e.value)} required className={classNames({ 'p-invalid': !isValidatedPolicyEndDate })} />
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-3">
                                    <label htmlFor="insuranceByPerson">{t('InsuranceByPerson')}  </label>
                                    <InputText id="insuranceByPerson" value={poolLog.insuranceByPerson} onChange={(e) => onInputChange(e, 'insuranceByPerson')} required className={classNames({ 'p-invalid': !isValidatedProductCode })} />
                                    {submitted && !poolLog.insuranceByPerson && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className="field col-3">
                                    <label htmlFor="insuredPersonName">{t('	InsuredPersonName')}  </label>
                                    <InputText id="insuredPersonName" value={poolLog.insuredPersonName} onChange={(e) => onInputChange(e, 'insuredPersonName')} required className={classNames({ 'p-invalid': !isValidatedProductCode })} />
                                    {submitted && !poolLog.insuredPersonName && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className="field col-3">
                                    <label htmlFor="insuredPersonTc">{t('InsuredPersonTc')}  </label>
                                    <InputText id="insuredPersonTc" value={poolLog.insuredPersonTc} onChange={(e) => onInputChange(e, 'insuredPersonTc')} required className={classNames({ 'p-invalid': !isValidatedProductCode })} />
                                    {submitted && !poolLog.insuredPersonTc && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className="field col-3">
                                    <label htmlFor="lastUser">{t('LastUser')}  </label>
                                    <InputText id="lastUser" value={poolLog.lastUser} onChange={(e) => onInputChange(e, 'lastUser')} required className={classNames({ 'p-invalid': !isValidatedProductCode })} />
                                    {submitted && !poolLog.lastUser && <small className="p-invalid">{t('Required')}</small>}
                                </div>

                            </div>

                            <div className="formgrid grid">
                                <div className="field col-2">
                                    <label htmlFor="grossPremium">{t('GrossPremium')}</label>
                                    <InputText id="grossPremium" value={poolLog.grossPremium} onChange={(e) => onInputChange(e, 'grossPremium')} required className={classNames({ 'p-invalid': !isValidatedGrossPremium })} />
                                    {submitted && !poolLog.grossPremium && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className="field col-1">
                                    <label htmlFor="tax">% {t('Tax')}</label>
                                    <InputText id="tax" value={poolLog.tax} onChange={(e) => onInputChange(e, 'tax')} required className={classNames({ 'p-invalid': !isValidatedTax })} />
                                    {submitted && !poolLog.tax && <small className="p-invalid">{t('Required')}</small>}
                                </div>

                                <div className="field col-2">
                                    <label htmlFor="currencyTypesId">{t('CurrencyType')}</label>
                                    <Dropdown id="currencyTypesId" value={selectCurrencyTypesValue} options={currencyTypes} onChange={(e) => onCurrencyChange(e, 'currencyTypesId')} optionLabel="name" placeholder={t('CurrencyType')} disabled={isInputDisabled} className={classNames({ 'p-invalid': !isValidatedSelectCurrencyTypesValue })} />
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="currencyRate">{t('CurrencyRate')}</label>
                                    <InputText id="currencyRate" value={poolLog.currencyRate} onChange={(e) => onInputChange(e, 'currencyRate')} required className={classNames({ 'p-invalid': !isValidatedCurrencyRate })} />
                                    {submitted && !poolLog.currencyRate && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className="field col-3">
                                    <label htmlFor="insuranceProductId">{t('InsuranceProduct')} </label>
                                    <Dropdown id="insuranceProductId" value={selectInsuranceProductsValue} filter options={insuranceProducts} onChange={(e) => onInsuranceProductsChange(e, 'insuranceProductId')} optionLabel="name" placeholder={t('InsuranceProduct')} className={classNames({ 'p-invalid': !isValidatedSelectInsuranceProductsValue })} />
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="incomingComm">{t('IncomingComm')} </label>
                                    <InputText id="incomingComm" value={poolLog.incomingComm} onChange={(e) => onInputChange(e, 'incomingComm')} required className={classNames({ 'p-invalid': !isValidatedIncomingComm })} />
                                    {submitted && !poolLog.incomingComm && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                                <div className="field col-2">
                                    <label htmlFor="subAccountProductCommPer">{t('SubAccountProductCommPer')} </label>
                                    <InputText id="subAccountProductCommPer" readonly value={poolLog.subAccountProductCommPer} onChange={(e) => onInputChange(e, 'subAccountProductCommPer')} required className={classNames({ 'p-invalid': !isValidatedSubAccountProductCommPer })} />
                                    {submitted && !poolLog.subAccountProductCommPer && <small className="p-invalid">{t('Required')}</small>}
                                </div>
                            </div>

                        </Dialog>
                        <Dialog visible={poolLogDeleteDialog} body style={{ width: '550px' }} header={t('Confirm')} modal footer={deletePoolLogDialogFooter} onHide={hideDeleteDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {poolLog && <span> {t('DeleteConfirmAre')}</span>}
                            </div>
                        </Dialog>
                        <Dialog visible={sendLogsDialog} style={{ width: '550px' }} header={t('Confirm')} modal footer={sendLogsDialogFooter} onHide={hideSendLogsDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {poolLog && <span> {t('SendLogsDialog')}</span>}
                            </div>
                        </Dialog>

                        <Dialog visible={sendDoubleLogsDialog} style={{ width: '550px' }} header={t('Confirm')} modal footer={sendDoubleLogsDialogFooter} onHide={hideSendDoubleLogsDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {poolLog && <span> {t('SendDoubleLogsDialog')}</span>}
                            </div>
                        </Dialog>
                        <Dialog visible={findDataLogsDialog} style={{ width: '550px' }} header={t('Confirm')} modal footer={findDataLogsDialogFooter} onHide={hideFindDataLogsDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {poolLog && <span> {t('FindDataLogsDialog')} </span>}
                            </div>
                        </Dialog>
                        <Dialog visible={sendOASendDataDialog} style={{ width: '550px' }} header={t('Confirm') + ' | Otomatik Aktarıcı Yöntemi'} modal footer={sendOASendDataDialogFooter} onHide={hideSendOASendDataDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {poolLog && <span> {t('Dikkat! Bu yöntem tek seferde maksimum 3000 adet poliçe için sırasıyla kartları eşleştir, borç alacak kontrolleri ve normal gönderim kontrollerinden geçecek. Poliçeler BAya aktarılacak, sorun olan poliçeler ise siz düzeltene kadar havuzda kalacak. Aktarım hızı havuzunuzdaki poliçe sayısı ile doğru orantılıdır. 3000 adet poliçede için internet ve sunucu hızınıza göre ortalama 15-20dk sürecektir. Evet butonuna bastıktan sonra ekranı kapatmanız durumunda işlem arka planda devam edecektir.')} </span>}
                            </div>
                        </Dialog>

                    </div>
                </div>
                <div>
                    <div className="card">
                        <h5>Otomatik Aktarıcı(OA) kullanmanız durumunda sorunlu poliçeler burada listelenir.</h5>
                        <ul>
                            {messageOA.map((msg, index) => (
                                <li key={index}>{msg}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );

}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(PoolLogs, comparisonFn);

