import React from 'react';
import { Button } from 'primereact/button';
import classNames from 'classnames';

const XLSX = require('xlsx');

const insuranceCompanyNameCheck = (value) => {
    if (value.insuranceProduct === undefined) {
        return "-";
    }
    else
        return value.insuranceProduct.insuranceCompany.name;
};

const formatDate = (value) => {
    if (value !== "") {
        return new Date(value).toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }
}

const formatCurrency = (value, currencyType = "TRY") => {
    if (value === undefined) {
        return 0;
    }

    return value.toLocaleString('en-US', { style: 'currency', currency: currencyType }).replace(',', '.');
};

const ExcelExport = ({ data, data3 }) => {

    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        const worksheet = XLSX.utils.aoa_to_sheet([
            ['', ''], // Boş satır 
            [data3.subAccountName], // Şirket adı 
            [data3.startDate, data3.startDate], // Boş satır 
            ['', ''], // Boş satır
            ['', ''], // Boş satır
            ['Sirket', 'Tarih', 'Poliçe No', 'Zeyil', 'Açıklama', 'Bas. Tar.', 'Bit. Tar.', 'Sigortali', 'Kur', 'Cinsi', 'Prim', 'Hiz.Bedl.', 'Tipi', 'Borç/Alacak', 'Bakiye TL', 'Bakiye Usd', 'Bakiye Eur'], // Sütun başlıkları
            ...data.map((item) => [
                insuranceCompanyNameCheck(item),
                formatDate(item.createdDate),
                item.policyNumber,
                item.addendumNo,
                item.description,
                formatDate(item.policyStartDate),
                formatDate(item.policyEndDate),
                item.insuredPersonName,
                item.deptCurrencyRate,
                item.currencyType.name,
                formatCurrency(item.grossPremium, item.currencyType.name),
                formatCurrency(item.serviceFee, item.currencyType.name),
                item.movementTypeId === 1 ? "Borç" : "Alacak",
                formatCurrency(item.movementAmount, item.currencyType.name),
                formatCurrency(item.balanceTl, "TRY"),
                formatCurrency(item.balanceUsd, "Usd"),
                formatCurrency(item.balanceEur, "Eur"),
            ]), // Veri satırları
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Company Data');

        XLSX.writeFile(workbook, `${data3.subAccountName}.xlsx`);
    };

    return (
        <div className="my-2">

            <Button label={('Export to Excel')} icon="pi pi-print" className="p-button-help mr-2" onClick={exportToExcel} />

            {/* <button onClick={exportToExcel} icon="pi pi-print" className="p-button-help mr-2"></button> */}
        </div>
        /* <div>
            <img src={companyLogo} alt="Company Logo" width="100" height="100" />
        </div>
        <div>
            <h1>{companyName}</h1>
        </div> */


    );
};

export default ExcelExport;
