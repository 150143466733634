import React from 'react';
import { Document, Page, Image, Text, View, StyleSheet, Font } from '@react-pdf/renderer';



Font.register({
  family: 'Times-Roman'
});

// Create styles
const styles = StyleSheet.create({

  page: {
    fontFamily: 'Times-Roman',
    padding: 20,
  },
  logo: {
    width: 120,
    height: 40
  },
  firmaAdi: {
    float: "right"
  },
  subAccountName: {
    margin: "auto",
    marginTop: 10,
    marginBottom: 5,
    fontSize: 15
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 15
  },
  tableDesc: {
    display: "table",
    width: "50%",
    marginLeft: '25%',
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 15
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    width: "5.88%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColDesc: {
    width: "14.2%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: "auto",
    marginTop: 3,
    marginBottom: 3,
    fontSize: 7
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: "10pt",
    color: "gray",
    marginTop: 20
  },
  info: {
    fontSize: 8,
    textAlign: 'center'
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  firma: {
    textAlign: "right",
    fontWeight: "bold",
    marginTop: 5
  },
  tableSubInfo: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 15,
    textAlign: "left"
  },
  tableColSubInfoLeft: {
    width: "100px",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left"
  },
  tableColSubInfoRight: {
    width: "400px",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left"
  },
});

const formatDate = (value) => {

  if (value !== "") {
    return new Date(value).toLocaleDateString('tr-TR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }
}

const formatCurrency = (value, currencyType = "TRY") => {
  if (value === undefined) {
    return 0;
  }

  return value.toLocaleString('en-US', { style: 'currency', currency: currencyType }).replace(',', '.');
};


const insuranceCompanyNameCheck = (value) => {
  if (value.insuranceProduct === undefined) {
    return "-";
  }
  else
    return value.insuranceProduct.insuranceCompany.name;
};


const formatTurkish = (value) => {
  if (value !== "") {
    let aa = value.replace(/Ğ/gim, "G").replace(/Ü/gim, "U").replace(/Ş/gim, "S").replace(/I/gim, "I").replace(/İ/gim, "I").replace(/Ö/gim, "O").replace(/Ç/gim, "C").replace(/ğ/gim, "g").replace(/ü/gim, "u").replace(/ş/gim, "s").replace(/ı/gim, "i").replace(/ö/gim, "o").replace(/ç/gim, "c");
    return aa;
  }
}

let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let todayText = date + "/" + month + "/" + year;


// Create Document Component
const DeptsToPdf = ({ data, data2, data3, data4 }) => (

  <Document>

    <Page size="A4" orientation="landscape" style={styles.page}>

      <View>
        <Text style={{ textAlign: "right", fontSize: 8 }}>{todayText}</Text>
      </View>
      <View style={styles.header}>
        <Image src="images/blocks/logos/logo.png" style={styles.logo} />
        {console.log('data4: ' + data4)}
        <Text style={styles.firma}>TS NET HAYAT SIGORTA VE ARACILIK HIZM.</Text>
      </View>
      <View style={styles.tableDesc}>
        <View style={styles.tableHeader}>
          <View style={styles.tableRow}>
            <View style={styles.tableColDesc}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColDesc}>
              <Text style={styles.tableCell}>Devir</Text>
            </View>
            <View style={styles.tableColDesc}>
              <Text style={styles.tableCell}>Prim</Text>
            </View>
            <View style={styles.tableColDesc}>
              <Text style={styles.tableCell}>Hiz. Bed.</Text>
            </View>
            <View style={styles.tableColDesc}>
              <Text style={styles.tableCell}>Borç</Text>
            </View>
            <View style={styles.tableColDesc}>
              <Text style={styles.tableCell}>Alacak</Text>
            </View>
            <View style={styles.tableColDesc}>
              <Text style={styles.tableCell}>Bakiye</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>Toplam TL</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPagePeriodTl)}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageNetPremiumTl)}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageServiceFeeTl)}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageDebtTl)}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageCreditorTl)}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageBalanceTl)}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>Toplam EUR</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPagePeriodEur, "Eur")}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageNetPremiumEur, "Eur")}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}> {formatCurrency(data2.totalPageServiceFeeEur, "Eur")}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}> {formatCurrency(data2.totalPageDebtEur, "Eur")}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageCreditorEur, "Eur")}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageBalanceEur, "Eur")}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>Toplam USD</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPagePeriodUsd, "Usd")}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageNetPremiumUsd, "Usd")}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageServiceFeeUsd, "Usd")}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageDebtUsd, "Usd")}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageCreditorUsd, "Usd")}</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>{formatCurrency(data2.totalPageBalanceUsd, "Usd")}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>Genel Toplam TL</Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>
              {formatCurrency(data2.totalPagePeriodEntire, "TRY")}
              {data4 ? " | (G.K.: " + formatCurrency(data2.totalPagePeriodEntireEquivalentRate, "TRY") + ")" : ""}
            </Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>
              {formatCurrency(data2.totalPageNetPremiumEntire)}
              {data4 ? " | (G.K.: " + formatCurrency(data2.totalPageNetPremiumEntireEquivalentRate) + ")" : ""}
            </Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>
              {formatCurrency(data2.totalPageServiceFeeEntire)}
              {data4 ? " | (G.K.: " + formatCurrency(data2.totalPageServiceFeeEntireEquivalentRate) + ")" : ""}
            </Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>
              {formatCurrency(data2.totalPageDebtEntire)}
              {data4 ? " | (G.K.: " + formatCurrency(data2.totalPageDebtEntireEquivalentRate) + ")" : ""}
            </Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>
              {formatCurrency(data2.totalPageCreditorEntire)}
              {data4 ? " | (G.K.: " + formatCurrency(data2.totalPageCreditorEntireEquivalentRate) + ")" : ""}
            </Text>
          </View>
          <View style={styles.tableColDesc}>
            <Text style={styles.tableCell}>
              {formatCurrency(data2.totalPageBalanceEntire)}
              {data4 ? " | (G.K.: " + formatCurrency(data2.totalPageBalanceEntireEquivalentRate) + ")" : ""}
            </Text>
          </View>
        </View>

      </View>

      <View>
        <Text style={styles.subAccountName}>{formatTurkish(data3.subAccountName)}</Text>
        <Text style={styles.info}>Tarih Araligi: {data3.startDate} - {data3.endDate}</Text>
      </View>


      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Sirket</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Tarih</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Poliçe No</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Zeyil</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Açiklama</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Bas. Tar.</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Bit. Tar</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Sigortali </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Kur</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Cinsi</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Prim</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Hiz.Bedl.</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Tipi</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Borç/Alacak</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Bakiye TL</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Bakiye Usd</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Bakiye Eur</Text>
            </View>
          </View>

        </View>
        {data.map((item, index) =>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{insuranceCompanyNameCheck(item)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{formatDate(item.createdDate)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.policyNumber}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.addendumNo}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.description}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{formatDate(item.policyStartDate)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{formatDate(item.policyEndDate)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell, { fontSize: 5 }]}>{formatTurkish(item.insuredPersonName)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.deptCurrencyRate}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.currencyType.name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{formatCurrency(item.grossPremium, item.currencyType.name)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{formatCurrency(item.serviceFee, item.currencyType.name)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.movementTypeId === 1 ? "Borç" : "Alacak"}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{formatCurrency(item.movementAmount, item.currencyType.name)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{formatCurrency(item.balanceTl, "TRY")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{formatCurrency(item.balanceUsd, "Usd")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{formatCurrency(item.balanceEur, "Eur")}</Text>
            </View>

          </View>
        )}
      </View>

      <View style={{ flexDirection: 'row', fontSize: 8, marginTop: 30 }}>
        {/* Sol sütun */}
        <View style={{ flex: 1 }}>

          <Text>MUSTERI NO</Text>
          <Text>BANKA</Text>
          <Text>SUBE</Text>
          <Text>IBAN NO (trl)</Text>
          <Text>IBAN NO (eur) </Text>
          <Text>IBAN NO (usd)  </Text>
          <Text>BEYOGLU VD  </Text>
        </View>

        {/* SaG sütun */}
        <View style={{ flex: 5 }}>
          <Text>: 23741937 - TS NETHAYAT SIGORTA ARACILIK HIZMETLERI LTD.STI. </Text>
          <Text>: GARANTI BANKASI</Text>
          <Text>: CUMHURIYET CADDESI</Text>
          <Text>: TR44 0006 2000 7720 0006 2987 41</Text>
          <Text>: TR71 0006 2000 7720 0009 0787 60</Text>
          <Text>: TR44 0006 2000 7720 0009 0787 61</Text>
          <Text>: 859 057 0769</Text>
        </View>
      </View>



    </Page>
  </Document>

);

export default DeptsToPdf;