export const deptsTotalModel = {
    totalPagePeriodTl: 0,
    totalPagePeriodUsd: 0,
    totalPagePeriodEur: 0,

    totalPageBalanceTl: 0,
    totalPageBalanceUsd: 0,
    totalPageBalanceEur: 0,

    totalPageServiceFeeTl: 0,
    totalPageServiceFeeUsd: 0,
    totalPageServiceFeeEur: 0,

    totalPageNetPremiumTl: 0,
    totalPageNetPremiumUsd: 0,
    totalPageNetPremiumEur: 0,

    totalPageCreditorUsd: 0,
    totalPageCreditorEur: 0,
    totalPageCreditorSumTl: 0,
    totalPageCreditorTl: 0,

    totalPageDebtTl: 0,
    totalPageDebtUsd: 0,
    totalPageDebtEur: 0,

    totalPageDebtEntire: 0,
    totalPageCreditorEntire: 0,
    totalPageNetPremiumEntire: 0,
    totalPageServiceFeeEntire: 0,
    totalPageBalanceEntire: 0,
    totalPagePeriodEntire: 0,

    totalPageDebtEntireEquivalentRate: 0,
    totalPageCreditorEntireEquivalentRate: 0,
    totalPageNetPremiumEntireEquivalentRate: 0,
    totalPageServiceFeeEntireEquivalentRate: 0,
    totalPageBalanceEntireEquivalentRate: 0,
    totalPagePeriodEntireEquivalentRate: 0
}