import { api } from '../../../constants/String';
import { HTTP } from '../../../axios/axios';
import { deptsModel } from '../models/depts.model';//Model



export function getDeptList() {
    debugger;
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Debts/getall`)
            .then(response => {
                debugger;
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function getDeptById(id) {
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Debts/getbyid/${id}`,)
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            }),
    );
}

export function refundDeptsToPool(selectedLogsIds) {
    debugger;
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Debts/refunddebtstopool`, { debtsIds: selectedLogsIds })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                debugger;
                rej(error);
            })
    );
}

export function filterDepts(filterModel) {
    debugger;

    return new Promise((res, rej) =>
        HTTP.post(`${api}/Debts/getlistforpaging`, {
            pageNumber: filterModel.pageNumber,
            pageSize: filterModel.pageSize,
            propertyName: filterModel.propertyName,
            sortType: true,
            startDate: filterModel.startDate,
            endDate: filterModel.endDate,
            subAccountId: filterModel.subAccountId,
            customerTypeId: filterModel.customerTypeId,
            dataInsertTypeId: filterModel.dataInsertTypeId,
            insuranceProductId: filterModel.insuranceProductId,
            search: filterModel.search,
            mainAccountId: filterModel.mainAccountId,
            showCreditCard: filterModel.showCreditCard
        })
            .then(response => {
                debugger;
                return res(response.data);
            })
            .catch((error) => {
                rej(error);
            })
    );
}

export function addDept(deptsModel) {
    debugger;
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Debts/add`, {
            subAccountId: deptsModel.subAccountId,
            insuranceProductId: deptsModel.insuranceProductId,
            currencyTypeId: deptsModel.currencyTypeId,
            customerTypeId: deptsModel.customerTypeId,
            paymentTypeId: deptsModel.paymentTypeId,
            description: deptsModel.description,
            policyStartDate: deptsModel.policyStartDate,
            policyEndDate: deptsModel.policyEndDate,
            issueDate: deptsModel.issueDate,
            serviceFee: deptsModel.serviceFee,
            tax: deptsModel.tax,
            policyNumber: deptsModel.policyNumber,
            insuredPersonName: deptsModel.insuredPersonName,
            insuredPersonTc: deptsModel.insuredPersonTc,
            netPremium: deptsModel.netPremium,
            grossPremium: deptsModel.grossPremium,
            incomingComm: deptsModel.incomingComm,
            subAccountCommPer: deptsModel.subAccountCommPer,
            companyComm: deptsModel.companyComm,
            creditCard: deptsModel.creditCard,
            lastUser: deptsModel.lastUser,
            addendumNo: deptsModel.addendumNo,
            dataInsertTypeId: deptsModel.dataInsertTypeId,
            currencyRate: deptsModel.currencyRate,
            currencyDate: deptsModel.currencyDate,
            movementAmount: deptsModel.movementAmount,
            incomingAmount: deptsModel.incomingAmount,
            movementTypeId: deptsModel.movementTypeId,
            deptCurrencyTypeId: deptsModel.deptCurrencyTypeId,
            deptCurrencyRate: deptsModel.deptCurrencyRate,
            deptCurrencyDate: deptsModel.deptCurrencyDate
        })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                debugger;
                rej(error.response.data);
            })
    );
}

export function updateDept(deptsModel) {
    return new Promise((res, rej) =>
        HTTP.put(`${api}/Debts/`, {
            id: deptsModel.id,
            subAccountId: deptsModel.subAccountId,
            insuranceProductId: deptsModel.insuranceProductId,
            currencyTypeId: deptsModel.currencyTypeId,
            customerTypeId: deptsModel.customerTypeId,
            paymentTypeId: deptsModel.paymentTypeId,
            description: deptsModel.description,
            policyStartDate: deptsModel.policyStartDate,
            policyEndDate: deptsModel.policyEndDate,
            issueDate: deptsModel.issueDate,
            serviceFee: deptsModel.serviceFee,
            tax: deptsModel.tax,
            policyNumber: deptsModel.policyNumber,
            insuredPersonName: deptsModel.insuredPersonName,
            insuredPersonTc: deptsModel.insuredPersonTc,
            netPremium: deptsModel.netPremium,
            grossPremium: deptsModel.grossPremium,
            incomingComm: deptsModel.incomingComm,
            subAccountCommPer: deptsModel.subAccountCommPer,
            companyComm: deptsModel.companyComm,
            creditCard: deptsModel.creditCard,
            lastUser: deptsModel.lastUser,
            addendumNo: deptsModel.addendumNo,
            dataInsertTypeId: deptsModel.dataInsertTypeId,
            currencyRate: deptsModel.currencyRate,
            currencyDate: deptsModel.currencyDate,
            movementAmount: deptsModel.movementAmount,
            incomingAmount: deptsModel.incomingAmount,
            movementTypeId: deptsModel.movementTypeId,
            deptCurrencyTypeId: deptsModel.deptCurrencyTypeId,
            deptCurrencyRate: deptsModel.deptCurrencyRate,
            deptCurrencyDate: deptsModel.deptCurrencyDate
        })
            .then(response => {
                if (typeof response.data === 'string') return res(response.data);
                return res({});
            })
            .catch((error) => {
                rej(error.response.data);
            })
    );
}


export function deleteDept(id) {
    return new Promise((res, rej) =>
        HTTP.delete(`${api}/Debts/`, { data: { id: id } })
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                rej(error.response.data);
            })
    );
}

export function getDebtElementersByproductid(dayCount, insuranceProductId) {
    debugger;
    let parmaters = `${dayCount}`;
    if (insuranceProductId !== null) {
        parmaters = parmaters + `&insuranceProductId=${insuranceProductId}`
    }
    return new Promise((res, rej) =>
        HTTP.get(`${api}/Debts/getdebtelementersbyproductid?dayCount=` + parmaters)
            .then(response => {
                debugger;
                return res(response.data);
            })
            .catch((error) => {
                debugger;
                rej(error);
            })
    );
}

export function updateElementersStatus(selectedLogsIds) {
    debugger;
    return new Promise((res, rej) =>
        HTTP.post(`${api}/Debts/updateelementerstatusdebts`, { debtIds: selectedLogsIds })
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {
                debugger;
                rej(error);
            })
    );
}
